/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import reactjs from "../../public/images/react.png";

const Portfolio = ({ data }) => {
  if (data) {
    var project1 = "images/portfolio/" + data.project1;
    var project2 = "images/portfolio/" + data.project2;
    var project3 = "images/portfolio/" + data.project3;
    var project4 = "images/portfolio/" + data.project4;
    var project5 = "images/portfolio/" + data.project5;
    var project6 = "images/portfolio/" + data.project6;
    var project7 = "images/portfolio/" + data.project7;
    var project8 = "images/portfolio/" + data.project8;
  } else {
  }

  return (
    <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1 className="text-gray-800">Check Out Some of My past Projects.</h1>

          <div
            id="portfolio-wrapper"
            className="bgrid-quarters s-bgrid-thirds cf"
          >
            <section className="lg:pt-[120px] pb-10 lg:pb-20 bg-[#F3F4F6]">
              <div className="container">
                <div className="flex flex-wrap mx-4">
                  <div className="w-full md:w-1/2 xl:w-1/3 px-4">
                    <div className="bg-white rounded-2xl overflow-hidden mb-10">
                      <img src={project1} alt="imag" className="w-full" />

                      <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                        <h3>
                          <a
                            href="http://jciremit.com/"
                            target="_blank"
                            className="font-semibold text-green-500 text-xl sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px] mb-4 block hover:text-primary"
                          >
                            JCI Remit Web App
                          </a>
                        </h3>
                        <p className="text-base text-body-color leading-relaxed mb-7">
                          Super Fast and Seemless Money Transfer Across the
                          Globe. with International payments to over 100
                          countries and territories, sending money to loved
                          ones. paying and receiving money from customers and
                          suppliers.
                        </p>
                        <p className="mb-4">
                          <span className="text-base bg-green-400 rounded px-2 py-1 m-2 text-white">
                            React.Js
                          </span>
                          <span className="text-base bg-purple-400 rounded px-2 py-1 m-2 text-white">
                            JavaScript
                          </span>
                          <span className="text-base bg-red-400 rounded px-2 py-1 m-2 text-white">
                            Redux
                          </span>
                          <span className="text-base bg-blue-400 rounded px-2 py-1 m-2 text-white">
                            CSS
                          </span>
                          <span className="text-base bg-gray-400 rounded px-2 py-1 m-2 text-white">
                            Git
                          </span>
                          <span className="text-base bg-pink-400 rounded px-2 py-1 m-2 text-white">
                            HTML
                          </span>
                        </p>
                        <a
                          href="https://github.com/Khareem23/JCI-Global-Web"
                          target="_blank"
                          className="inline-block py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Github
                        </a>
                        <a
                          href="http://jciremit.com/"
                          target="_blank"
                          className="inline-block ml-2 py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Visit
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 xl:w-1/3 px-4">
                    <div className="bg-white rounded-2xl overflow-hidden mb-10">
                      <img src={project2} alt="imag" className="w-full" />

                      <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                        <h3>
                          <a
                            href="https://sigmaweb.sigmapensions.com/ChannelPortal/Account/Login/?ReturnUrl=/ChannelPortal/"
                            target="_blank"
                            className="font-semibold text-green-500 text-xl sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px] mb-4 block hover:text-primary"
                          >
                            Sigma Pensions Portal
                          </a>
                        </h3>
                        <p className="text-base text-body-color leading-relaxed mb-7">
                          This is a web portal for Sigma pensions customers to
                          manage their respective pension accounts. <br></br>
                          <br></br>
                          <br></br>
                        </p>
                        <p className="mb-4">
                          <span className="text-base bg-green-400 rounded px-2 py-1 m-2 text-white">
                            React.Js
                          </span>
                          <span className="text-base bg-purple-400 rounded px-2 py-1 m-2 text-white">
                            JavaScript
                          </span>
                          <span className="text-base bg-red-400 rounded px-2 py-1 m-2 text-white">
                            Redux
                          </span>
                          <span className="text-base bg-blue-400 rounded px-2 py-1 m-2 text-white">
                            CSS
                          </span>
                          <span className="text-base bg-pink-400 rounded px-2 py-1 m-2 text-white">
                            HTML
                          </span>
                        </p>
                        <a
                          href="#"
                          disable={true}
                          className="inline-block py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Private Repo
                        </a>
                        <a
                          href="https://sigmaweb.sigmapensions.com/ChannelPortal/Account/Login/?ReturnUrl=/ChannelPortal/"
                          target="_blank"
                          className="inline-block ml-2 py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Visit
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 xl:w-1/3 px-4">
                    <div className="bg-white rounded-2xl overflow-hidden mb-10">
                      <img src={project3} alt="imag" className="w-full" />

                      <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                        <h3>
                          <a
                            href="https://altmall.ng/"
                            target="_blank"
                            className="font-semibold text-green-500 text-xl sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px] mb-4 block hover:text-primary"
                          >
                            AltMall Ecommerce App
                          </a>
                        </h3>
                        <p className="text-base text-body-color leading-relaxed mb-7">
                          The Alternative Mall (TAM) is an e-commerce platform
                          developed by Sterling Alternative Finance (SAF) to
                          provide customers great shopping experience and
                          flexible payment options
                          <br></br>
                        </p>
                        <p className="mb-4">
                          <span className="text-base bg-red-400 rounded px-2 py-1 m-2 text-white">
                            Vuex
                          </span>
                          <span className="text-base bg-green-400 rounded px-2 py-1 m-2 text-white">
                            Vue.Js
                          </span>
                          <span className="text-base bg-blue-400 rounded px-2 py-1 m-2 text-white">
                            CSS
                          </span>

                          <span className="text-base bg-purple-400 rounded px-2 py-1 m-2 text-white">
                            JavaScript
                          </span>
                          <span className="text-base bg-pink-400 rounded px-2 py-1 m-2 text-white">
                            HTML
                          </span>
                        </p>
                        <a
                          href="#"
                          disable={true}
                          className="inline-block py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Private Repo
                        </a>
                        <a
                          href="https://altmall.ng/"
                          target="_blank"
                          className="inline-block ml-2 py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Visit
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 xl:w-1/3 px-4">
                    <div className="bg-white rounded-2xl overflow-hidden mb-10">
                      <img src={project4} alt="imag" className="w-full" />

                      <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                        <h3>
                          <a
                            href="https://aftjdigital.com/"
                            target="_blank"
                            className="font-semibold text-green-500 text-xl sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px] mb-4 block hover:text-primary"
                          >
                            AFTj Digital Website
                          </a>
                        </h3>
                        <p className="text-base text-body-color leading-relaxed mb-7">
                          We help businesses boost performance and enhance
                          productivity by providing services that equip them to
                          gain relevance in the marketplace
                          <br></br>
                        </p>
                        <p className="mb-4">
                          <span className="text-base bg-blue-400 rounded px-2 py-1 m-2 text-white">
                            CSS
                          </span>

                          <span className="text-base bg-purple-400 rounded px-2 py-1 m-2 text-white">
                            JavaScript
                          </span>
                          <span className="text-base bg-pink-400 rounded px-2 py-1 m-2 text-white">
                            HTML
                          </span>
                        </p>
                        <a
                          href="#"
                          disable={true}
                          className="inline-block py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Private Repo
                        </a>
                        <a
                          href="https://aftjdigital.com/"
                          target="_blank"
                          className="inline-block ml-2 py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Visit
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 xl:w-1/3 px-4">
                    <div className="bg-white rounded-2xl overflow-hidden mb-10">
                      <img src={project5} alt="imag" className="w-full" />

                      <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                        <h3>
                          <a
                            href="https://culturaltourism-2-0.sterlingapps.p.azurewebsites.net/"
                            target="_blank"
                            className="font-semibold text-green-500 text-xl sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px] mb-4 block hover:text-primary"
                          >
                            Adventure360 Web App
                          </a>
                        </h3>
                        <p className="text-base text-body-color leading-relaxed mb-7">
                          We believe brand interaction is key in communication.
                          Real innovations and a positive customer experience
                          are the heart of successful communication
                          <br></br>
                        </p>
                        <p className="mb-4">
                          <span className="text-base bg-green-400 rounded px-2 py-1 m-2 text-white">
                            React.Js
                          </span>
                          <span className="text-base bg-purple-400 rounded px-2 py-1 m-2 text-white">
                            JavaScript
                          </span>
                          <span className="text-base bg-red-400 rounded px-2 py-1 m-2 text-white">
                            Redux
                          </span>
                          <span className="text-base bg-blue-400 rounded px-2 py-1 m-2 text-white">
                            CSS
                          </span>
                          <span className="text-base bg-gray-400 rounded px-2 py-1 m-2 text-white">
                            Azure
                          </span>
                          <span className="text-base bg-pink-400 rounded px-2 py-1 m-2 text-white">
                            HTML
                          </span>
                        </p>
                        <a
                          href="#"
                          disable={true}
                          className="inline-block py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Private Repo
                        </a>
                        <a
                          href="https://culturaltourism-2-0.sterlingapps.p.azurewebsites.net/"
                          target="_blank"
                          className="inline-block ml-2 py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Visit
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 xl:w-1/3 px-4">
                    <div className="bg-white rounded-2xl overflow-hidden mb-10">
                      <img src={project6} alt="imag" className="w-full" />

                      <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                        <h3>
                          <a
                            href="https://culturaltourism-2-0.sterlingapps.p.azurewebsites.net/"
                            target="_blank"
                            className="font-semibold text-green-500 text-xl sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px] mb-4 block hover:text-primary"
                          >
                            Travel Booking App
                          </a>
                        </h3>
                        <p className="text-base text-body-color leading-relaxed mb-7">
                          This is an app for booking flights, hotel and other
                          flight itinerary
                          <br></br>
                          <br></br>
                        </p>
                        <p className="mb-4">
                          <span className="text-base bg-green-400 rounded px-2 py-1 m-2 text-white">
                            Next.Js
                          </span>
                          <span className="text-base bg-blue-400 rounded px-2 py-1 m-2 text-white">
                            Tailwind
                          </span>
                          <span className="text-base bg-purple-400 rounded px-2 py-1 m-2 text-white">
                            JavaScript
                          </span>
                          <span className="text-base bg-red-400 rounded px-2 py-1 m-2 text-white">
                            Redux
                          </span>

                          <span className="text-base bg-pink-400 rounded px-2 py-1 m-2 text-white">
                            SSR
                          </span>
                          {/* <span className="text-base bg-pink-400 rounded px-2 py-1 m-2 text-white">
                            HTML
                          </span> */}
                        </p>
                        <a
                          href="#"
                          disable={true}
                          className="inline-block py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Private Repo
                        </a>
                        <a
                          href="https://culturaltourism-2-0.sterlingapps.p.azurewebsites.net/"
                          target="_blank"
                          className="inline-block ml-2 py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Private Link
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="twelve columns collapsed">
          <h1 className="text-gray-800">
            Check Out Some of Leisure Time Projects
          </h1>

          <div
            id="portfolio-wrapper"
            className="bgrid-quarters s-bgrid-thirds cf"
          >
            <section className="lg:pt-[120px] pb-10 lg:pb-20 bg-[#F3F4F6]">
              <div className="container">
                <div className="flex flex-wrap mx-4">
                  <div className="w-full md:w-1/2 xl:w-1/3 px-4">
                    <div className="bg-white rounded-2xl overflow-hidden mb-10">
                      <img src={project7} alt="imag" className="w-full" />

                      <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                        <h3>
                          <a
                            href="https://google-search-blush.vercel.app/"
                            target="_blank"
                            className="font-semibold text-green-500 text-xl sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px] mb-4 block hover:text-primary"
                          >
                            Google Search Demo
                          </a>
                        </h3>
                        <p className="text-base text-body-color leading-relaxed mb-7">
                          This is a demo of the Google Search Engine
                        </p>
                        <p className="mb-4">
                          <span className="text-base bg-green-400 rounded px-2 py-1 m-2 text-white">
                            Next.Js
                          </span>
                          <span className="text-base bg-purple-400 rounded px-2 py-1 m-2 text-white">
                            JavaScript
                          </span>
                          <span className="text-base bg-red-400 rounded px-2 py-1 m-2 text-white">
                            TailwindCSS
                          </span>
                          {/* <span className="text-base bg-blue-400 rounded px-2 py-1 m-2 text-white">
                            CSS
                          </span> */}
                          <span className="text-base bg-gray-400 rounded px-2 py-1 m-2 text-white">
                            Git
                          </span>
                          <span className="text-base bg-pink-400 rounded px-2 py-1 m-2 text-white">
                            HTML
                          </span>
                        </p>
                        <a
                          href="https://github.com/packaging03/Google_Search"
                          target="_blank"
                          className="inline-block py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Github
                        </a>
                        <a
                          href="https://google-search-blush.vercel.app/"
                          target="_blank"
                          className="inline-block ml-2 py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Visit
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 xl:w-1/3 px-4">
                    <div className="bg-white rounded-2xl overflow-hidden mb-10">
                      <img src={project8} alt="imag" className="w-full" />

                      <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                        <h3>
                          <a
                            href="https://pokeman-app.vercel.app/"
                            target="_blank"
                            className="font-semibold text-green-500 text-xl sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px] mb-4 block hover:text-primary"
                          >
                            Pokemon Search App
                          </a>
                        </h3>
                        <p className="text-base text-body-color leading-relaxed mb-7">
                          A simple app for search Pokemon. It runs on Pokemon
                          library
                        </p>
                        <p className="mb-4">
                          <span className="text-base bg-green-400 rounded px-2 py-1 m-2 text-white">
                            React.Js
                          </span>
                          <span className="text-base bg-purple-400 rounded px-2 py-1 m-2 text-white">
                            JavaScript
                          </span>
                          <span className="text-base bg-red-400 rounded px-2 py-1 m-2 text-white">
                            Redux
                          </span>
                          <span className="text-base bg-blue-400 rounded px-2 py-1 m-2 text-white">
                            CSS
                          </span>
                          <span className="text-base bg-pink-400 rounded px-2 py-1 m-2 text-white">
                            HTML
                          </span>
                        </p>
                        <a
                          href="https://github.com/packaging03/PokemanApp"
                          disable={true}
                          className="inline-block py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Github
                        </a>
                        <a
                          href="https://pokeman-app.vercel.app/"
                          target="_blank"
                          className="inline-block ml-2 py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-white hover:bg-gray-900 transition"
                        >
                          Visit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
